import { Component, input } from '@angular/core'

@Component({
  template: `<div [innerHTML]="api().formattedAddress"></div>`,
  standalone: false,
})
export class ConfirmAddressComponent {
  api = input.required<{
    formattedAddress: string
  }>()
}
