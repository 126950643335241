import { Component, input } from '@angular/core'

@Component({
  selector: 'app-free-therm-modal',
  templateUrl: './free-therm-modal.component.html',
  styleUrls: ['./free-therm-modal.component.scss'],
  standalone: false,
})
export class FreeThermModalComponent {
  protected api = input.required<{
    select: (b: boolean) => Promise<boolean>
  }>()
}
