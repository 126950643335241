import { Component, input } from '@angular/core'
import { CartState } from '@naturalcycles/shared'

@Component({
  selector: 'app-confirm-discount',
  templateUrl: './confirm-discount.component.html',
  styleUrls: ['./confirm-discount.component.scss'],
  standalone: false,
})
export class ConfirmCartComponent {
  protected api = input.required<{
    cart: CartState
    title: string
    content: string
    footer: string
  }>()
}
