@if ($modals().length) {
  @let modals = $modals();
  @let lastModal = modals[modals.length - 1];
  <div class="modal__container">
    <div
      class="modal__background"
      (click)="lastModal?.skipBackdropClose || clear()"
      [class.modal__background___light]="lastModal?.lightPurpleBackground"
    ></div>

    @for (modal of modals; track modalName) {
      <div
        class="modal"
        [class.sheet]="modal.sheetStyle"
        [class.modal___centered]="modal.modalCentered"
        [@fadeAnimation]="fadeAnimation"
      >
        @if (!modal.hideCloseButton) {
          <div (click)="clear(modal.control)" class="modal__close"></div>
        }

        <div class="modal__body" [class.sheet]="modal.sheetStyle">
          @if (modal.titleKey) {
            <div class="modal__title">{{ modal.titleKey | translate }}</div>
          }

          <ng-container
            modalHost
            *ngComponentOutlet="modal.component; inputs: { api: modal.api }"
          />

          @if (modal.controls) {
            <div class="modal__controls">
              @for (control of modal.controls; track key) {
                <app-button
                  text="blue"
                  [label]="control.key | translate"
                  (click)="clear(control)"
                />
              }
            </div>
          }

          @if (!modal.skipControls && !modal.controls) {
            <div class="modal__controls">
              <app-button text="blue" [label]="'btn-ok' | translate" (click)="clear()" />
            </div>
          }
        </div>
      </div>
    }
  </div>
}
