import { inject, Injectable } from '@angular/core'
import { _errorDataAppend, _filterNullishValues, AppError, pTry } from '@naturalcycles/js-lib'
import {
  ActivationChannel,
  AppId,
  AuthMode,
  BackendResponseFM,
  cookieService,
  ErrorCode,
  Goal,
  HardwareId,
  LoginOrRegisterInput,
  normalizeLanguage,
  RecaptchaAction,
  WebSignupQueryParam,
} from '@naturalcycles/shared'
import { userDeviceService } from '@src/app/core/services/user-device.service'
import { SignalStore } from '@src/app/core/store/signalStore'
import { sentry } from '@src/app/core/util/sentry.util'
import { AuthProviderType } from '@src/app/shared/typings/enum/auth'
import {
  AuthProviderResult,
  EmailAuthProviderResult,
  SocialAuthProviderResult,
} from '@src/app/shared/typings/interfaces/user-auth'
import { isSignupMode } from '../util/authMode.util'
import { AccountService } from './account.service'
import { CartService } from './cart.service'
import { NavService } from './nav.service'
import { RecaptchaService } from './recaptcha.service'
import { sessionSigningService } from './sessionSigning.service'

@Injectable({ providedIn: 'root' })
export class AuthService {
  private store = inject(SignalStore)
  private accountService = inject(AccountService)
  private cartService = inject(CartService)
  private recaptchaService = inject(RecaptchaService)
  private navService = inject(NavService)

  async createOrLogin(result: AuthProviderResult): Promise<BackendResponseFM | undefined> {
    switch (result.type) {
      case AuthProviderType.email: {
        return await this.createOrLoginWithEmail(result as EmailAuthProviderResult)
      }
      case AuthProviderType.social: {
        return await this.createOrLoginWithSocialAuth(result as SocialAuthProviderResult)
      }
      default: {
        throw new Error(`Unknown auth provider type: ${result.type}`)
      }
    }
  }

  private async createOrLoginWithEmail(
    result: EmailAuthProviderResult,
  ): Promise<BackendResponseFM> {
    const userDevice = userDeviceService.getUserDeviceInput()
    if (result.mode === AuthMode.login) {
      return await this.accountService.login({
        email: result.input.email,
        pw: result.input.pw,
        userDevice,
        publicKey: await sessionSigningService.getPublicKey(),
      })
    }

    const recaptchaToken = await this.recaptchaService.generateAndStoreRecaptchaToken(
      RecaptchaAction.register,
    )

    const { quiz, lang, cart, account, discountCode } = this.store.getState()
    // If the user can select a monthly plan and opt out of the therm
    // We currently set the hwId to T1 even though the user is using their own thermometer
    cart.hwId ||= HardwareId.ORAL_THERMOMETER

    const hasClearblueCodeApplied = this.cartService.hasClearblueCodeApplied(discountCode)

    const body: LoginOrRegisterInput = {
      email: result.input.email,
      pw: result.input.pw,
      recaptchaToken,
      ...result.consent,
      plan: this.cartService.getSubscriptionType(),
      lang: normalizeLanguage(lang),
      userDevice,
      goal: account.goal,
      irclickid: cookieService.getCookie(WebSignupQueryParam.irclickid),
      hwId: cart.hwId,
      regFlow: Number(this.navService.getQueryParam(WebSignupQueryParam.flow || '')) || undefined,
      quizData: quiz.data || undefined,
      publicKey: await sessionSigningService.getPublicKey(),
      ...(isSignupMode(result.mode) && {
        appId: hasClearblueCodeApplied ? AppId.CLEARBLUE : AppId.NC,
        activationChannel: hasClearblueCodeApplied
          ? ActivationChannel.CLEARBLUE
          : ActivationChannel.NC,
      }),
      discountCode: discountCode?.code,
    }

    const response = await this.accountService.createOrLogin(_filterNullishValues(body))
    if (!result.input.accountAlreadyExists) {
      void this.processPostRegistrationSideEffects()
    }

    return response
  }

  private async createOrLoginWithSocialAuth(
    result: SocialAuthProviderResult,
  ): Promise<BackendResponseFM | undefined> {
    const { goal } = this.store.$account()
    const [errorSigningIn, response] = await pTry(this.accountService.socialAuth(result))
    if (!errorSigningIn) {
      if (!response?.account?.completeDate && result.input.accountAlreadyExists) {
        /**
         * If a user drops off before completing but after registering,
         * then goes through the flow again with a different goal, we should
         * patch goal with the new value.
         */
        await this.patchAccountGoal(goal)
      }
      if (!result.input.accountAlreadyExists) {
        void this.processPostRegistrationSideEffects()
      }
      return response
    }

    if (
      errorSigningIn instanceof AppError &&
      errorSigningIn.cause?.data.code === ErrorCode.ACCOUNT_EXISTS
    ) {
      const password = await this.accountService.linkAccountWithPassword(
        result.input.loginProvider,
        result.input.email,
      )
      result.input.pw = password
      const [errorLinking, response] = await pTry(this.accountService.socialAuth(result))
      if (errorLinking) {
        await this.accountService.errorLinkingAccount(errorLinking, result.input.email)
        return
      }
      if (!response?.account?.completeDate) {
        await this.patchAccountGoal(goal)
      }
      return response
    }

    throw errorSigningIn
  }

  private async patchAccountGoal(goal?: Goal): Promise<void> {
    if (!goal) return
    try {
      await this.accountService.patch({
        goal,
      })
    } catch (err) {
      sentry.captureException(_errorDataAppend(err, { fingerprint: 'patchAccountGoal' }))
    }
  }

  private async processPostRegistrationSideEffects(): Promise<void> {
    void this.accountService.sendVerificationEmail()
  }
}
