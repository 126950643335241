import { Routes } from '@angular/router'
import { Page } from '@src/app/shared/typings/enum/pages'

export const ab308Routes: Routes = [
  {
    path: Page.hormonesInfo,
    loadComponent: () =>
      import('./pages/hormones-info/hormones-info.page.component').then(m => m.HormonesInfoPage),
    data: { page: Page.hormonesInfo },
  },
  {
    path: Page.noHormonesInfo,
    loadComponent: () =>
      import('./pages/no-hormones-info/no-hormones-info.page.component').then(
        m => m.NoHormonesInfoPage,
      ),
    data: { page: Page.noHormonesInfo },
  },
]
