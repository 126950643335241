import { Component, effect, inject, OnDestroy, OnInit, output, signal } from '@angular/core'
import { _stringEnumValues } from '@naturalcycles/js-lib'
import { ConsentTermsItemToggleKey } from '@naturalcycles/shared'
import { ConsentInfoModalComponent } from '@src/app/components/modals/consent-info-modal/consent-info-modal.component'
import { api } from '@src/app/core/services/api.service'
import { ModalService } from '@src/app/core/services/modal.service'
import { SignalStore } from '@src/app/core/store/signalStore'
import { sentry } from '@src/app/core/util/sentry.util'
import { AcceptConsentEvent } from '../../shared/typings/interfaces/events'

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  preserveWhitespaces: true,
  styleUrls: ['./consent.component.scss'],
  standalone: false,
})
export class ConsentComponent implements OnInit, OnDestroy {
  constructor() {
    effect(() => {
      _stringEnumValues(ConsentTermsItemToggleKey).forEach(key => {
        const toggleInfo = this.$terms()?.items.find(
          item => item.toggleInfo?.key === key,
        )?.toggleInfo
        if (toggleInfo) {
          this.toggleMap.update(old => ({ ...old, [key]: toggleInfo.defaultChecked }))
        } else {
          sentry.captureException(new Error(`No auth terms toggle found for key: ${key}`))
        }
      })
    })
  }

  protected store = inject(SignalStore)
  private modalService = inject(ModalService)

  protected hideGoToBottomContainer = true
  protected $terms = this.store.$consent
  protected toggleMap = signal<Record<ConsentTermsItemToggleKey, boolean>>({
    marketing: true,
    socialMarketing: true,
  })

  protected accept = output<AcceptConsentEvent>()
  protected decline = output<void>()

  public ngOnInit(): void {
    this.checkViewportStage = this.checkViewportStage.bind(this)

    window.addEventListener('scroll', this.checkViewportStage)
    setTimeout(this.checkViewportStage, 100)
  }

  public ngOnDestroy(): void {
    window.removeEventListener('scroll', this.checkViewportStage)
  }

  private checkViewportStage(): void {
    const consentButtons = document.querySelector('.consent__buttons')
    if (consentButtons) {
      const bounding = consentButtons.getBoundingClientRect()

      if (bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
        this.hideGoToBottomContainer = true
      } else {
        this.hideGoToBottomContainer = false
      }
    }
  }

  protected onLanguageChange(lang: string): void {
    void this.fetchTerms(lang)
  }

  private async fetchTerms(lang: string): Promise<void> {
    await api.get('consent/terms', { searchParams: { lang } })
  }

  protected onClickItemInfo(info: string): void {
    void this.modalService.show({
      component: ConsentInfoModalComponent,
      modalName: 'ConsentInfoModal',
      api: {
        content: info,
      },
    })
  }

  protected onClickItemToggle(key: ConsentTermsItemToggleKey): void {
    this.toggleMap.update(old => ({ ...old, [key]: !old[key] }))
  }

  protected goToButtonComponent(): void {
    /* The buttons are at the bottom of the container, but just using scrollIntoView({ block: 'end' }) 
    on them behaves a bit weirdly, so we just go to the end of the container instead. */
    const container = document.querySelector('.container')
    container?.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }

  protected async acceptClick(): Promise<void> {
    const terms = this.store.$consent()

    const marketingSocialConsentItem = terms.items.find(
      item => item.toggleInfo?.key === ConsentTermsItemToggleKey.socialMarketing,
    )

    const toggleMap = this.toggleMap()
    this.accept.emit({
      marketingConsent: toggleMap.marketing,
      marketingSocialConsent: toggleMap.socialMarketing,
      marketingSocialConsentTo: marketingSocialConsentItem?.text,
      marketingSocialConsentKey: marketingSocialConsentItem?.key,
      consentTermsLanguage: this.store.$consent().language,
    })
  }

  protected declineClick(): void {
    this.decline.emit()
  }
}
