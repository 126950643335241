<div>
  <div uid="consent__page" class="consent">
    <div class="consent__header">
      <h3 class="page-title">{{ $terms().header }}</h3>
      <select class="consent__languageSelect" (change)="onLanguageChange($event.target.value)">
        @for (option of $terms().languageOptions; track option) {
          <option [value]="option.value" [selected]="option.value === $terms().language">
            {{ option.label }}
          </option>
        }
      </select>
    </div>
    <div class="consent__description">{{ $terms().description }}</div>

    <div class="consentTerms">
      @for (item of $terms().items; track item) {
        <div class="consentTermsItem">
          <div class="consentTermsItem--start">
            @if (!item.info && !item.toggleInfo) {
              <div class="consentTermsItem--dot"></div>
            }
            @if (!!item.toggleInfo) {
              <app-checkbox
                uid="registerCredentials__marketingConsent"
                ghost
                [selected]="toggleMap()[item.toggleInfo.key]"
                (click)="onClickItemToggle(item.toggleInfo.key)"
                color="blue"
              />
            }
            @if (!!item.info) {
              <i class="nc__icon__info" (click)="onClickItemInfo(item.info)"></i>
            }
          </div>
          <div class="consentTermsItem--text" [innerHTML]="item.text"></div>
        </div>
      }
    </div>

    <div class="consent__buttons">
      <app-button
        uid="decline-consent"
        color="white"
        text="purple"
        border="purple"
        label="{{ $terms().declineButtonLabel }}"
        (click)="declineClick()"
      />
      <app-button
        id="consent__agreeConsent"
        uid="agree-consent"
        color="purple"
        label="{{ $terms().acceptButtonLabel }}"
        (click)="acceptClick()"
        [disabled]="store.$loading()"
        [class.loading]="store.$loading()"
      />
    </div>

    <div class="go-to-bottom-container" [class.hide]="hideGoToBottomContainer">
      <div class="go-to-bottom-green-icon" (click)="goToButtonComponent()"></div>
    </div>
  </div>
</div>
