import { Routes } from '@angular/router'
import { Page } from '@src/app/shared/typings/enum/pages'

export const ab314Routes: Routes = [
  {
    path: Page.resultOverview,
    loadComponent: () =>
      import('./pages/result-overview/result-overview.page.component').then(
        m => m.ResultOverviewPage,
      ),
    data: { page: Page.resultOverview },
  },
]
