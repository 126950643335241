import { Component, input, ViewEncapsulation } from '@angular/core'

@Component({
  template: '<div [innerHTML]="api().content | md"></div>',
  styleUrls: ['./consent-info-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class ConsentInfoModalComponent {
  protected api = input.required<{ content: string }>()
}
